import StravaActivities from './StravaActivities';
import bikingBronze from './activities/biking-bronze.json';
import bikingSilver from './activities/biking-silver.json';
import bikingGold from './activities/biking-gold.json';

const KOT = () => (
  <>
    <StravaActivities title="Kolarska Odznaka Turystyczna - stopień brązowy"
      activities={bikingBronze} />
    <hr />
    <StravaActivities title="Kolarska Odznaka Turystyczna - stopień srebrny"
      activities={bikingSilver} />
    <hr />
    <StravaActivities title="Kolarska Odznaka Turystyczna - stopień złoty"
      activities={bikingGold} />
  </>
);

export default KOT;
