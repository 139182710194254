import { useEffect } from 'react';
import styled from 'styled-components';
import StravaActivity from './StravaActivity';

const Activities = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const StravaActivities = ({ title, activities }) => {
    useEffect(() => {
      const body = document.querySelector('body');
      const script = document.createElement('script');
      script.src = 'https://strava-embeds.com/embed.js';
      body.appendChild(script);

      return () => {
        body.removeChild(script);
      };
    }, []);

    return (
      <>
        <h3>{title}</h3>
        <Activities>
          {activities.map((activityId) =>
            <StravaActivity key={`activity-${activityId}`} activityId={activityId}/>)}
        </Activities>
      </>
      );
};

export default StravaActivities;
