import styled from 'styled-components';

const EmbededMap = styled.div`
  display: flex;
  justify-content: center;

  iframe {
    border: none;
  }
`;

const CrownOfSudeten = () => (
  <>
    <h3>Korona Sudetów</h3>
    <EmbededMap>
      <iframe
        title="crown-of-sudeten-map"
        src={process.env.REACT_APP_EMBEDED_MAP_URL}
        width="640"
        height="480">
      </iframe>
    </EmbededMap>
  </>
);

export default CrownOfSudeten;
