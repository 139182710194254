import { Link } from 'react-router';

const App = () => (
  <ul>
    <li><Link to="got">Górska odznaka turystyczna</Link></li>
    <li><Link to="kot">Kolarska odznaka turystyczna</Link></li>
    <li><Link to="sudety">Korona Sudetów</Link></li>
  </ul>
);

export default App;
