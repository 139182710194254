import styled from 'styled-components';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router';
import App from './App';
import CrownOfSudeten from './CrownOfSudeten';
import Currencies from './Currencies';
import GOT from './GOT';
import Header from './Header';
import KOT from './KOT';
import NotFound from './NotFound';

const Root = styled.div`
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Root>
    <BrowserRouter>
      <Header title="Lambda Monada" />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="currencies" element={<Currencies />} />
        <Route path="got" element={<GOT />} />
        <Route path="kot" element={<KOT />} />
        <Route path="sudety" element={<CrownOfSudeten />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  </Root>
);
