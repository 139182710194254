import styled from 'styled-components';
import { Link } from 'react-router';

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 48px;
  }

  a {
    font-size: 1.5em;
    font-weight: bold;
    color: #000000;
    line-height: 48px;
    margin-left: 10px;
    text-decoration: none;
  }
`;

const Header = ({ title }) => (
  <StyledHeader>
    <img src="/logo192.png" alt={title} />
    <Link to="/">{title}</Link>
  </StyledHeader>
);

export default Header;
