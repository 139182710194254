import StravaActivities from './StravaActivities';
import hikingPopular from './activities/hiking-popular.json';
import hikingSmallBronze from './activities/hiking-small-bronze.json';

const GOT = () => (
  <>
    <StravaActivities title="Górska Odznaka Turystyczna - popularna"
      activities={hikingPopular} />
    <hr />
    <StravaActivities title="Górska Odznaka Turystyczna - mała brązowa"
      activities={hikingSmallBronze} />
  </>
);
    ;

export default GOT;
