import styled from 'styled-components';

const Root = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const Title = styled.div`
  font-size: 144px;
`;

const Description = styled.div`
  font-size: 18px;
`;

const NotFound = () => (
    <Root>
      <Title>404</Title>
      <Description>Nie znaleziono strony o podanym adresie</Description>
    </Root>
  );
  
  export default NotFound;